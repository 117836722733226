
import { useStore } from "vuex";

import { onMounted, ref, inject } from "vue";
import Button from "./Button.vue";
import Input from "./Input.vue";

import { GET, DELETE, POST } from "../../services/api";
import { useRoute, useRouter } from "vue-router";
import { isEmpty } from "../../services/validate";
import alert from "@/services/alert";

export default {
  name: "modalConfirmation",
  components: {
    Input,
    Button,
  },

  setup() {
    const store = useStore();
    const idVenda = ref("");
    const msg = ref();
    const motivos: any[] = [];
    const motivo = ref();
    const router = useRouter();
    const route = useRoute();
    const loader: any = inject("loading");

    onMounted(async () => {
      try {
        loader.open();
        const response = await GET(`motivos_cancelamento`);
        //console.log(response)
        response.map((elemento) =>
          motivos.push({ label: elemento.motivo, value: elemento.id })
        );
        motivo.value = "";
        loader.close();
      } catch (error) {
        loader.close();
      }
    });

    const close = () => {
      msg.value = false;
      idVenda.value = "";
      motivo.value = "";
      store.commit("modalConfirmation", { display: false });
    };

    const confirmar = async () => {
      if (motivo.value == "") {
        alert.open("Atenção!", "Selecione um motivo", "warning");
        motivo.value = "";
        return;
      } else if (idVenda.value == "" || idVenda.value == null) {
        alert.open("Atenção!", "Digite o id da venda", "warning");
        idVenda.value = "";
        return;
      } else if (idVenda.value != route.params.id) {
        alert.open("Atenção!", "O id da venda está incorreto!", "error");
        return;
      } else {
        const [motivoId] = motivos.filter(
          (elemento) => elemento.label == motivo.value
        );
        console.log(idVenda.value, motivo.value);
        try {
          loader.open();
          const body = {
            "id_venda": idVenda.value,
            "id_motivo": motivoId.value,
          };

          const response = await POST(`vendas/cancelar`, body);

          alert.open("Sucesso!", "Venda cancelada com sucesso", "success");
          idVenda.value = "";
          motivoId.value = "";
          motivo.value = "";
          router.push("/vendas");

          store.commit("modalConfirmation", { display: false });
          loader.close();
        } catch (e: any) {
          console.log(e);
          alert.open("Error", e, "error");
          loader.close();
        }
      }
    };

    return { store, close, idVenda, msg, confirmar, motivos, motivo };
  },
};
