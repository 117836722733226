<template>
  <div v-if="store.getters.modalTrocaMotivo.display" x-transition:enter="transition ease-out duration-150"
    x-transition:enter-start="opacity-0" x-transition:enter-end="opacity-100"
    x-transition:leave="transition ease-in duration-150" x-transition:leave-start="opacity-100"
    x-transition:leave-end="opacity-0"
    class="fixed inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center">
    <div x-transition:enter="transition ease-out duration-150"
      x-transition:enter-start="opacity-0 transform translate-y-1/2" x-transition:enter-end="opacity-100"
      x-transition:leave="transition ease-in duration-150" x-transition:leave-start="opacity-100"
      x-transition:leave-end="opacity-0  transform translate-y-1/2" @keydown.escape="closeModal"
      class="w-full px-6 py-4 overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl"
      role="dialog">
      <div class="modal">
        <h6>Atenção!</h6>
        <p>
          Você está mundando o motivo do cancelamento da venda :
          <b>{{ route.params.id }} </b>
        </p>
        <p>Selecione um motivo abaixo e clique em confirmar</p>
      </div>

      <div class="confirmar">
        <div>
          <Input type="select" id="motivo" v-model="motivo" label="Motivo"
            placeholder="Selecione um motivo de cancelamento" :data="motivos" />
        </div>
        <div class="buttons">
          <Button @click="close" color="yellow-500" text="Sair" />
          <Button @click="confirmar" color="primary" text="Confirmar" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";

import { onMounted, ref,inject } from "vue";
import Button from "./Button.vue";
import Input from "./Input.vue";

import { GET, PUT } from "../../services/api";
import { useRouter, useRoute } from "vue-router";
import { isEmpty } from "../../services/validate";

export default {
  name: "modalTrocaMotivoCancelamento",

  components: {
    Input,
    Button,
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const motivos = [];
    const motivo = ref();
    const loader = inject("loading");
    onMounted(async () => {
      try {
        loader.open();
        const response = await GET(`motivos_cancelamento`);
        //console.log(response)
        response.map((elemento) =>
          motivos.push({ label: elemento.motivo, value: elemento.id })
        );
        motivo.value = "";
        loader.close();
      } catch (error) {
        loader.close();
      }
    });


    const close = () => {
      motivo.value = "";
      store.commit("modalTrocaMotivo", { display: false });
    };

    const confirmar = async () => {
      if (motivo.value != '') {
        try {
          store.commit("loading", true);
          const body = {
            'id_motivo': motivo.value
          }

          await PUT(`venda_cancelamento/${store.getters.modalTrocaMotivo.id}`, body)
          motivo.value = "";


          store.commit("modalTrocaMotivo", { display: false });
          store.commit("loading", false);
          router.push("/vendas");
        } catch (error) {
          motivo.value = "";
          store.commit("alert", { display: true, title: "Atenção!", message: `Algum erro ocorreu, tente novamente`, type: "warning" });
          store.commit("loading", false);
        }
      } else {
        alert('Selecione um motivo.')
      }

    }


    return {
      store,
      route,
      router,
      motivos,
      motivo,
      close,
      confirmar
    };
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.modal {
  text-align: center;
  background-color: white;
  margin-top: 10%;
  border-radius: 20px;
}

.close {
  margin: 10% 0 0 16px;
  cursor: pointer;
}

.close-img {
  width: 25px;
}

.check {
  width: 150px;
}

h6 {
  font-weight: 500;
  font-size: 28px;
  margin: 20px 0;
}

p {
  font-size: 16px;
  margin: 20px 0;
}

.confirmar {
  width: 98%;
  display: inline-block;
  padding: auto;
  margin: 15px;
}

.buttons {
  margin-top: 15px;
  display: flex;
  justify-content: space-around;
}

.msg {
  font-size: 12px;
  color: red;
}

.input {
  display: grid;

  margin-top: 25px;
  margin-bottom: 2px;
  width: 100%;
}
</style>
